export interface IGroup {
    title: string;
    tiles: ITile[];
}

export interface ITile {
    title: string;
    href: string;
    icon: URL;
}

export const startMenu: IGroup[] = [
    {
        title: '管理',
        tiles: [
            {
                title: '光猫',
                href: 'http://192.168.1.1/',
                icon: new URL('./images/icons/cat.svg', import.meta.url),
            },
            {
                title: 'AC86U',
                href: 'http://192.168.1.3/',
                icon: new URL('./images/icons/router.svg', import.meta.url),
            },
            {
                title: 'AC66U',
                href: 'http://192.168.1.4/',
                icon: new URL('./images/icons/router.svg', import.meta.url),
            },
            {
                title: 'OpenWRT',
                href: 'http://192.168.1.2/',
                icon: new URL('./images/icons/router.svg', import.meta.url),
            },
            {
                title: 'Switch',
                href: 'http://192.168.1.5/',
                icon: new URL('./images/icons/switch.svg', import.meta.url),
            },
            {
                title: 'NAS',
                href: 'https://nas.h.cfm.moe:5001/',
                icon: new URL('./images/icons/nas.svg', import.meta.url),
            },
            {
                title: 'Surge Dash',
                href: 'http://192.168.1.6:6166/web/index.html#/',
                icon: new URL('./images/icons/surge.svg', import.meta.url),
            },
        ]
    }
]